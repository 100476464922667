export default {
  min: {
    1430: "89.375em",
    1410: "88.125em",
    1350: "84.375em",
    1290: "80.625em",
    1200: "75em",
    1080: "67.5em",
    1024: "64em",
    960: "60em",
    840: "52.5em",
    768: "48em",
    720: "45em",
    600: "37.5em",
    560: "35em",
    480: "30em",
    360: "22.5em",
    320: "20em",
  },

  max: {
    1530: "95.625em",
    1430: "89.3125em",
    1410: "88.0625em",
    1350: "84.3125em",
    1290: "80.5625em",
    1200: "74.9375em",
    1080: "67.4375em",
    1024: "63.9375em",
    960: "59.9375em",
    840: "52.4375em",
    768: "47.9375em",
    720: "44.9375em",
    600: "37.4375em",
    560: "34.9375em",
    480: "29.9375em",
    360: "22.4375em",
    320: "19.9375em",
  },

  layout: {
    phoneStart: `${(320 / 16)}em`,
    phoneEnd: `${(767 / 16)}em`,
    tabletStart: `${(768 / 16)}em`,
    tabletEnd: `${(1023 / 16)}em`,
    laptopStart: `${(1024 / 16)}em`,
    laptopEnd: `${(1353 / 16)}em`,
    desktopStart: `${(1354 / 16)}em`,
  },
};
