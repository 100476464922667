import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import radium from "radium"

import colors from "../../styles/colors"
import timing from "../../styles/timing"

import CoverPhoto from "../coverPhoto"
import propTypes from "../../utils/propTypes"

const styles = {
  container: {
    overflow: "hidden",
    position: "relative",
  },

  anchor: {
    display: "block",
  },

  coverPhoto: {
    transition: `transform ${timing.slow} ease-in-out`,
  },
}

const imageSizes = {
  video: {
    width: 410,
    height: 232,
  },
  poster: {
    width: 300,
    height: 378,
  },
}

const CardGatsbyImage = ({
  href,
  onClick,
  fluid,
  aspectRatio,
  children,
  opacity,
  style,
}) => (
  <div
    className="Card-image"
    style={[
      styles.container,
      opacity && { backgroundColor: colors.bgOverlay },
      style,
    ]}
  >
    <Link to={href} onClick={onClick} tabIndex={-1} style={styles.anchor}>
      {fluid && (
        <CoverPhoto
          fluid={fluid}
          width={imageSizes[aspectRatio].width}
          height={imageSizes[aspectRatio].height}
          style={styles.coverPhoto}
        />
      )}

      {children}
    </Link>
  </div>
)

CardGatsbyImage.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  fluid: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(["video", "poster"]).isRequired,
  children: PropTypes.node,
  opacity: PropTypes.number,
  style: propTypes.style,
}

CardGatsbyImage.defaultProps = {
  aspectRatio: "video",
}

export default radium(CardGatsbyImage)
