import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "react-emotion"
import ArrowForwardIcon from "react-icons/lib/md/arrow-forward"
import presets from "../../utils/presets"
import { SCROLLER_CLASSNAME } from "../../utils/scrollers-observer"
import { linkStyles } from "../../utils/styles"
import { rhythm } from "../../utils/typography"
import {
  HorizontalScroller,
  HorizontalScrollerContent,
} from "../shared/horizontal-scroller"
import CardBlog from "./card-blog"
import { keyframes } from "react-emotion"

const HomepageBlogPostsRootMobile = styled(HorizontalScroller)`
  margin: -6px -${rhythm(presets.gutters.default / 2)};
`

const HorizontalScrollerContentAsDiv = HorizontalScrollerContent.withComponent(
  `div`
)

const stripeAnimation = keyframes({
  "0%": { backgroundPosition: `0 0` },
  "100%": { backgroundPosition: `30px 60px` },
})

const ViewAllLink = styled(Link)`
  ${linkStyles.viewAll};
  overflow: hidden;
  width: ${rhythm(6)};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  span {
    margin-left: ${rhythm(2)};
    align-items: center;
    display: flex;
  }

  svg {
    height: 18px;
    margin-left: 0.2rem;
    width: 18px;
  }
`

const ViewAll = () => (
  <ViewAllLink to="/blog/">
    <span>全部</span>
    <span>
      动态
      <ArrowForwardIcon />
    </span>
  </ViewAllLink>
)

class HomepageBlogPosts extends Component {
  desktopMediaQuery

  state = {
    desktopViewport: false,
  }

  componentDidMount = () => {
    this.desktopMediaQuery = window.matchMedia(presets.desktop)
    this.desktopMediaQuery.addListener(this.updateViewPortState)
    this.setState({ desktopViewport: this.desktopMediaQuery.matches })
  }

  componentWillUnmount = () => {
    this.desktopMediaQuery.removeListener(this.updateViewPortState)
  }

  updateViewPortState = e => {
    this.setState({ desktopViewport: this.desktopMediaQuery.matches })
  }

  splitPostsToColumns = posts =>
    posts.reduce(
      (merge, post, idx) => {
        if (idx % 2) {
          merge[1].push(post)
        } else {
          merge[0].push(post)
        }

        return merge
      },
      [[], []]
    )

  render() {
    const { posts } = this.props
    const postsInColumns = this.splitPostsToColumns(posts)
    const { desktopViewport } = this.state

    return (
      <React.Fragment>
        <HomepageBlogPostsRootMobile className={SCROLLER_CLASSNAME}>
          <HorizontalScrollerContentAsDiv>
            {posts.map((post, idx) => {
              const {
                fields: { slug },
              } = post
              return <CardBlog index={idx} key={slug} post={post} />
            })}
            <ViewAll />
          </HorizontalScrollerContentAsDiv>
        </HomepageBlogPostsRootMobile>
      </React.Fragment>
    )
  }
}

HomepageBlogPosts.propTypes = {
  posts: PropTypes.array.isRequired,
}

export default HomepageBlogPosts
