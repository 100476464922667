import React from "react"
import PropTypes from "prop-types"
import radium from "radium"
import {
  Card,
  CardAnchor,
  CardBullets,
  CardHeading,
  CardImage,
  CardGatsbyImage,
  CardText,
} from "../card"
import propTypes from "../../utils/propTypes"

const CardPost = ({ href, fluid, heading, bullets, style }) => (
  <Card style={style}>
    <CardGatsbyImage href={href} fluid={fluid} />

    <CardText>
      <CardAnchor slug={href}>
        {bullets && bullets.length > 0 && <CardBullets bullets={bullets} />}

        <CardHeading>{heading}</CardHeading>
      </CardAnchor>
    </CardText>
  </Card>
)

CardPost.propTypes = {
  href: PropTypes.string.isRequired,
  fluid: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: propTypes.style,
}

export default radium(CardPost)
