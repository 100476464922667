import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import radium from "radium"
import colors from "../../styles/colors"

const CoverPhoto = ({ fluid, src, width, height, alt, style }) =>
  fluid ? (
    <Img fluid={fluid} className="CoverPhoto" style={style} />
  ) : (
    <div
      className="CoverPhoto"
      aria-label={alt}
      style={[
        {
          backgroundColor: colors.borderPrimary,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        },
        style,
        { backgroundImage: `url(${src})` },
        width && height && { paddingBottom: `${(height / width) * 100}%` },
      ]}
    />
  )

CoverPhoto.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
}

export default radium(CoverPhoto)
