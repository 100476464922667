import React from "react"
import styled from "react-emotion"
import BoeSvg from "./boe.svg"
import CanonSvg from "./canon.svg"
import ChinapostSvg from "./chinapost.svg"
import BSHJpg from "./bsh.png"
import TianWeiJpg from "./tianwei.png"

const LogoImage = styled(`img`)`
  margin-right: 1em;
`

const BoeLogo = () => {
  return <LogoImage src={BoeSvg} width="120px" height="100px" />
}

const BSHLogo = () => {
  return <LogoImage src={BSHJpg} width="120px" />
}

const CanonLogo = () => {
  return <LogoImage src={CanonSvg} width="120px" />
}

const ChinapostLogo = () => {
  return <LogoImage src={ChinapostSvg} width="180px" />
}

const TianWeiLogo = () => {
  return <LogoImage src={TianWeiJpg} width="120px" />
}

export { BoeLogo, BSHLogo, CanonLogo, ChinapostLogo, TianWeiLogo }
