import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import ArrowForwardIcon from "react-icons/lib/md/arrow-forward"

import { HorizontalScrollerItem } from "../shared/horizontal-scroller"

import presets, { colors } from "../../utils/presets"
import { rhythm, options } from "../../utils/typography"
import CardPost from "../../share/components/cardPost"

const carBlogStyle = {
  fontFamily: options.systemFontFamily.join(`,`),
  maxWidth: "450px",
  width: "77vw",
  position: "relative",
  margin: 0,
  marginRight: rhythm(presets.gutters.default / 2),
}

const CardBlog = ({
  post,
  first = false,
  fullWidth = false,
  desktopViewport = false,
}) => {
  const {
    excerpt: automaticExcerpt,
    fields: { slug },
    frontmatter: {
      excerpt: handwrittenExcerpt,
      author: {
        id: authorName,
        avatar: {
          childImageSharp: { fixed: authorFixed },
        },
        fields: { slug: authorSlug },
      },
      date,
      title,
      cover,
    },
  } = post

  const excerpt = handwrittenExcerpt ? handwrittenExcerpt : automaticExcerpt

  return (
    <CardPost
      style={carBlogStyle}
      heading={title}
      bullets={[excerpt]}
      fluid={cover.childImageSharp.fluid}
      href={slug}
    />
  )
}

CardBlog.propTypes = {
  post: PropTypes.object.isRequired,
  first: PropTypes.bool,
  fullWidth: PropTypes.bool,
  desktopViewport: PropTypes.bool,
}

export const blogPostFragment = graphql`
  fragment BlogPostData on MarkdownRemark {
    excerpt
    fields {
      slug
    }
    frontmatter {
      excerpt
      title
      date
      author {
        id
        fields {
          slug
        }
        avatar {
          childImageSharp {
            fixed(
              width: 30
              height: 30
              quality: 80
              traceSVG: {
                turdSize: 10
                background: "#f6f2f8"
                color: "#e0d6eb"
              }
            ) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
      cover {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default CardBlog
