import React from "react"
import { keyframes } from "react-emotion"
import presets, { colors } from "../../utils/presets"
import { options, rhythm, scale } from "../../utils/typography"
import FuturaParagraph from "../futura-paragraph"
import { vP } from "../gutters"

const lineAnimation = keyframes({
  to: {
    strokeDashoffset: 10,
  },
})

const Segment = ({ className, children }) => (
  <div
    className={`Segment ${className}`}
    css={{
      maxWidth: rhythm(30),
      textAlign: `center`,
    }}
  >
    {children}
  </div>
)

const SegmentTitle = ({ children }) => (
  <h2
    className="Segment-title"
    css={{
      display: `inline`,
      background: colors.accent,
      color: colors.gray.copy,
      borderRadius: presets.radius,
      margin: `0 auto`,
      position: `relative`,
      bottom: `-.5rem`,
      padding: `.35rem .6rem`,
      fontWeight: `normal`,
      letterSpacing: `.5px`,
      ...scale(-2 / 5),
      lineHeight: 1,
      textTransform: `uppercase`,
      transform: `translateZ(0)`,
    }}
  >
    {children}
  </h2>
)

const VerticalLine = () => (
  <svg
    width="20"
    height="30"
    viewBox="0 0 20 30"
    css={{ margin: `0 auto`, display: `block` }}
  >
    <path
      d="M10 40 L10 -10"
      css={{
        stroke: colors.lilac,
        strokeWidth: `3`,
        strokeLinecap: `round`,
        strokeDasharray: `0.5 10`,
        animation: `${lineAnimation} 400ms linear infinite`,
      }}
    />
  </svg>
)

const box = {
  border: `1px solid ${colors.ui.light}`,
  borderRadius: presets.radiusLg,
  padding: `${rhythm(1)} ${rhythm(1)} 0`,
  background: `transparent`,
}

const SourceItems = ({ children }) => (
  <div
    css={{
      display: `flex`,
      flexWrap: `wrap`,
      justifyContent: `center`,
      ...box,
    }}
  >
    {children}
  </div>
)

const ItemTitle = ({ children }) => (
  <h3
    css={{
      color: `#fff`,
      margin: 0,
      fontStyle: `normal`,
      ...scale(0),
    }}
  >
    {children}
  </h3>
)

const ItemDescription = ({ children }) => (
  <small
    css={{
      lineHeight: 1.2,
      display: `block`,
      color: `#eee`,
      [presets.Hd]: {
        fontSize: scale(-1 / 5).fontSize,
      },
    }}
  >
    {children}
  </small>
)

const History = () => {
  return (
    <section
      css={{
        borderRadius: presets.radiusLg,
        fontFamily: options.headerFontFamily.join(`,`),
        padding: vP,
        marginTop: rhythm(1),
        textAlign: `center`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Segment className="Deploy">
        <SegmentTitle>2003年9月</SegmentTitle>
        <div
          css={{
            ...box,
            paddingBottom: rhythm(1),
          }}
        >
          <ItemTitle>北京祥龙物流有限公司</ItemTitle>
          <ItemDescription>
            以北京市大型物资运输公司为基础，对原北京市交通局所属的货运枢纽经营企业、货运代理企业、道路货物运输等企业按照现代企业制度重组改制注册的国有一人公司。
          </ItemDescription>
        </div>
      </Segment>

      <Segment className="Deploy">
        <VerticalLine />
        <SegmentTitle>2010年9月</SegmentTitle>
        <div
          css={{
            ...box,
            paddingBottom: rhythm(1),
          }}
        >
          <ItemTitle>北京祥龙物流（集团）有限公司</ItemTitle>
          <ItemDescription>
            与原北京市物资总公司下属的北京市恒物物流公司进行重组，成立北京祥龙物流（集团）有限公司。
          </ItemDescription>
        </div>
      </Segment>

      <Segment className="Deploy">
        <VerticalLine />
        <SegmentTitle>2014年初</SegmentTitle>
        <div
          css={{
            ...box,
            paddingBottom: rhythm(1),
          }}
        >
          <ItemTitle>北京祥龙物流（集团）有限公司</ItemTitle>
          <ItemDescription>
            根据祥龙公司整体工作安排，祥龙物流与原北京一商局商业储运公司进行资产重组，公司的经营实力进一步加强。
          </ItemDescription>
        </div>
      </Segment>
    </section>
  )
}

export default History
