export const benton = [
  "'Benton Sans'",
  "'Helvetica Neue'",
  "'Helvetica'",
  "'Arial'",
  "sans-serif",
];

export const miller = [
  "'Miller Daily'",
  "'Georgia'",
  "'Times'",
  "'Times New Roman'",
  "serif",
];
