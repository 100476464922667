import React from "react"
import styled from "react-emotion"
import presets, { colors } from "../../utils/presets"
import { options, rhythm } from "../../utils/typography"
import CardBasic from "../../share/components/cardBasic"

const Section = styled(`section`)`
  background: ${props => (props.inverse ? colors.gatsby : `#fff`)};
  color: ${props => (props.inverse ? colors.ui.light : colors.gatsbyDark)};
  margin: ${rhythm(presets.gutters.default / 2)};
  padding: ${rhythm(presets.gutters.default / 2)};
  width: calc(100%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  ${presets.Desktop} {
    flex-direction: row;
    justify-content: space-around;
    > * {
      width: 30%;
    }
  }

  border-radius: ${presets.radiusLg}px;
  box-shadow: 0 5px 20px rgba(25, 17, 34, 0.1);
  transform: translateZ(0);
`

const serviceStyle = {
  maxWidth: "520px",
  margin: `${rhythm(presets.gutters.default / 2)} 0`,
}

const Services = ({ posts }) => (
  <Section>
    <CardBasic
      style={serviceStyle}
      heading="综合物流"
      bullets={[
        "公司现有仓库面积38万平方米",
        "分布在北京东部、东南和南部",
        "提供全方位的物流解决方案，为您节约资源，节省精力",
      ]}
      imageSrc="/images/business-cargo-cargo-container.jpg"
      href="#"
    />
    <CardBasic
      style={serviceStyle}
      heading="仓储与配送"
      bullets={[
        "充足的城市通行运力，为您提供可靠的市内配送服务",
        "拥有优质的电子信息服务以及物流金融服务和国际货代服务",
      ]}
      imageSrc="/images/jezael-melgoza.jpg"
      href="#"
    />
    <CardBasic
      style={serviceStyle}
      heading="公路运输"
      bullets={[
        "涉足超限件运输、长途干线运输和城市配送等多个领域",
        "多次承担国家重点工程及重大政治活动的运输任务",
      ]}
      imageSrc="/images/asphalt-cars-clouds.jpg"
      href="#"
    />
  </Section>
)

export default Services
