import React from "react"
import styled from "react-emotion"
import {
  BoeLogo,
  BSHLogo,
  CanonLogo,
  ChinapostLogo,
  TianWeiLogo,
} from "../../assets/customer-logos"
import presets, { colors } from "../../utils/presets"
import { rhythm } from "../../utils/typography"

const SubSection = styled(`section`)`
  margin: ${rhythm(presets.gutters.default / 2)};
  padding: ${rhythm(presets.gutters.default / 2)};

  display: flex;
  flex-direction: column;
  align-items: center;
`
const LogoContain = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 70%;
`

const Title = styled(`h5`)`
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
  margin-bottom: 3em;
  :before {
    border-top: 1px solid ${colors.gatsbyDark};
    border-bottom: 1px solid ${colors.gatsbyDark};
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    height: 6px;
    z-index: -1;
  }
`
const TitleText = styled(`span`)`
  color: ${colors.ui.light};
  background: ${colors.gatsby};
  padding: 0 10px;
`

const Logos = () => {
  return (
    <SubSection>
      <Title>
        <TitleText>满意的客户</TitleText>
      </Title>
      <LogoContain>
        <CanonLogo />
        <BoeLogo />
        <BSHLogo />
        <TianWeiLogo />
        <ChinapostLogo />
      </LogoContain>
    </SubSection>
  )
}

export default Logos
