import React from "react"
import styled from "react-emotion"
import {
  FieldIcon,
  WarehouseIcon,
  TruckIcon,
  PeopleIcon,
} from "../../assets/resources"
import presets, { colors } from "../../utils/presets"
import { rhythm } from "../../utils/typography"

const SubSection = styled(`section`)`
  margin: ${rhythm(presets.gutters.default / 2)};
  padding: ${rhythm(presets.gutters.default / 2)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  ${presets.Desktop} {
    flex-direction: column;
  }
`

const Item = styled(`div`)`
  width: 80%;
  max-width: ${rhythm(20)};
  margin: ${rhythm(presets.gutters.default / 2)};
`
const ICON_SIZE = `32px`

const Title = styled(`h5`)`
  display: flex;
  align-items: center;
  color: ${props => (props.inverse ? colors.ui.light : colors.lilac)};
  font-size: 1rem;
  margin: 0;
  // margin-left: calc(${ICON_SIZE} * -1.2);
`

const Icon = styled(`span`)`
  display: block;

  margin-right: calc(${ICON_SIZE} / 5);

  svg {
    fill: ${props => (props.inverse ? colors.ui.light : colors.lilac)};
    height: ${ICON_SIZE};
    stroke: ${props => (props.inverse ? colors.ui.light : colors.lilac)};
    width: ${ICON_SIZE};
  }
`
const ItemTitle = ({ icon, children }) => (
  <Title inverse={true}>
    {icon && <Icon dangerouslySetInnerHTML={{ __html: icon }} inverse={true} />}
    {children}
  </Title>
)

const ItemDescription = styled(`small`)`
  color: #eee;
  display: block;
  margin-left: calc(${ICON_SIZE} * 1.2);
`

const Resources = () => {
  return (
    <SubSection>
      <Item>
        <ItemTitle icon={FieldIcon}>物流园区</ItemTitle>
        <ItemDescription>
          北京东部、东南、西南等6处，占地面积251万平方米
        </ItemDescription>
      </Item>

      <Item>
        <ItemTitle icon={WarehouseIcon}>仓库面积</ItemTitle>
        <ItemDescription>34万平方米</ItemDescription>
      </Item>

      <Item>
        <ItemTitle icon={TruckIcon}>自有车辆</ItemTitle>
        <ItemDescription>
          191辆：大型运输车145辆，奔驰重型牵引车4辆，箱式货车46辆
        </ItemDescription>
      </Item>

      <Item>
        <ItemTitle icon={PeopleIcon}>员工</ItemTitle>
        <ItemDescription>在职员工1232人，大专以上学历370人</ItemDescription>
      </Item>
    </SubSection>
  )
}

export default Resources
