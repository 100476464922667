import React from "react"
import styled from "react-emotion"
import { EcosystemIcon } from "../../assets/mobile-nav-icons"
import presets, { colors } from "../../utils/presets"
import History from "../introduction/history"
import Logos from "../introduction/logos"
import Resources from "../introduction/resources"
import HomepageSection from "./homepage-section"

const HSContainer = styled(`div`)`
  display: flex;
  flex-direction: column;

  ${presets.Tablet} {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`
const TitleContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
`

const Title = styled(`h1`)`
  color: ${colors.lemon};
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
`

const Introduction = ({ posts }) => (
  <HomepageSection
    sectionName="企业介绍"
    sectionIcon={EcosystemIcon}
    inverseStyle={true}
  >
    <TitleContainer>
      <Title>北京祥龙物流（集团）有限公司</Title>
    </TitleContainer>
    <HSContainer>
      <History />
      <Resources />
    </HSContainer>
    <Logos />
  </HomepageSection>
)

export default Introduction
