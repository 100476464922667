import { graphql } from "gatsby"
import { StyleRoot } from "radium"
import React from "react"
import styled from "react-emotion"
import { Helmet } from "react-helmet"
import HomepageBlog from "../components/homepage/homepage-blog"
import Services from "../components/homepage/services"
import Introduction from "../components/homepage/introduction"
import Layout from "../components/layout"
import MastheadContent from "../components/masthead"
import MastheadBg from "../components/masthead-bg"
import {
  setupScrollersObserver,
  unobserveScrollers,
} from "../utils/scrollers-observer"

const MainContent = styled("div")({
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  width: `100%`,
})

class Homepage extends React.Component {
  componentDidMount() {
    setupScrollersObserver()
  }

  componentWillUnmount() {
    unobserveScrollers()
  }

  render() {
    const {
      data: {
        allMarkdownRemark: { edges: postsData },
      },
    } = this.props

    const posts = postsData.map(item => item.node)

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <meta
            name="Description"
            content="Blazing fast modern site generator for React. Go beyond static sites: build blogs, ecommerce sites, full-blown apps, and more with Gatsby."
          />
        </Helmet>
        <StyleRoot>
          <div css={{ position: `relative` }}>
            <MastheadBg />
            <MainContent>
              <MastheadContent />
              <div
                css={{
                  // padding: rhythm(presets.gutters.default / 2),
                  // paddingBottom: 0,
                  flex: `0 0 100%`,
                  maxWidth: `100%`,
                  // [presets.Hd]: {
                  //   padding: vP,
                  //   paddingTop: 0,
                  //   paddingBottom: 0,
                  // },
                }}
              >
                <main
                  id={`reach-skip-nav`}
                  css={{
                    display: `flex`,
                    flexDirection: `row`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                  }}
                >
                  <Services />
                  <Introduction posts={posts} />

                  <HomepageBlog posts={posts} />
                </main>
              </div>
            </MainContent>
          </div>
        </StyleRoot>
      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "gatsby-explanation.png" }) {
      childImageSharp {
        fluid(maxWidth: 870) {
          src
          srcSet
          sizes
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, fields___slug] }
      limit: 4
      filter: {
        frontmatter: { draft: { ne: true } }
        fileAbsolutePath: { regex: "/docs.blog/" }
        fields: { released: { eq: true } }
      }
    ) {
      edges {
        node {
          ...HomepageBlogPostData
        }
      }
    }
  }
`
